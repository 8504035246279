import ReactDOM from "react-dom/client";
import dayjsDuration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "./main.less";
import { enableMapSet } from "immer";
import { subscribe } from "pubsub-js";
import { Toast } from "react-vant";
import App from "./pages/App";

enableMapSet();

dayjs.extend(dayjsDuration);
dayjs.extend(utc);
dayjs.extend(timezone);

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);

subscribe("webapi_net_err", (_, error: string) => {
  Toast({
    message: error,
    position: "top",
  });
});

export default App;
