import React, { Suspense } from "react";
import { createMemoryRouter, Outlet, RouterProvider } from "react-router-dom";
import { LayoutLoading } from "@/comps/LayoutLoading";

const CheckIn = React.lazy(() => import("./CheckIn"));
const TenantCreate = React.lazy(() => import("./TenantCreate"));

const router = createMemoryRouter([
  {
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <CheckIn />,
      },
      {
        path: "/tenantCreate",
        element: <TenantCreate />,
      },
    ],
  },
]);

function App() {
  return (
    <Suspense fallback={<LayoutLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
